import requests from "utils/httpService";

const getRequests = {
  getGiftCards: (merchantId) =>
    requests.manageServiceRequests.get(
      `/loyalties/loyalty/rewards/published/list/${merchantId}`
    ),
  getGiftCardsCategories: (merchantId) =>
    requests.loyaltyRequests.get(
      `/admin/discounts/category/list/${merchantId}`
    ),
  getCustomerRecentLoyaltyPoints: ({ merchant, customer_phone, order }) =>
    requests.manageServiceRequests.get(
      `/orders/order/process/${merchant}/customer/${customer_phone}/${order}`
    ),
  getUserLoyaltyPoints: ({ customer_id, merchant }) =>
    requests.loyaltyRequests.get(`/admin/wallet/${customer_id}/${merchant}`),
  getCustomerRedeemedVouchers: ({ merchant, phone }) =>
    requests.loyaltyRequests.get(
      `admin/discounts/voucher/customer/claims/all/${merchant}/${phone}`
    ),
  getCustomerTransactions: ({ merchant, customer_id }) =>
    requests.loyaltyRequests.get(
      `/admin/transactions/merchant/${merchant}/user/${customer_id}`
    ),
  checkUser: (mobileNumber) =>
    requests.manageServiceRequests.get(
      `/customers/customer/lookup/${mobileNumber}`
    ),
};

export default getRequests;
