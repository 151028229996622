import axios from "axios";
import Cookies from "js-cookie";
import { sha512 } from "js-sha512";
import qs from "qs";

export function microtime() {
  return (Date.now ? Date.now() : new Date().getTime()) / 1000;
}

export function getHash(string, key) {
  var hash = sha512.hmac.create(key);
  hash.update(string);
  return hash.hex();
}

export function getHeaders() {
  const timestamp = Math.round(microtime());
  const stringedTimestamp = String(timestamp);
  const appID = process.env.REACT_APP_API_APP_ID;
  const appKey = process.env.REACT_APP_API_APP_KEY;
  const authData = `${appID}:${stringedTimestamp}`;
  const authSecret = getHash(authData, appKey);

  return {
    Application: appID,
    Time: stringedTimestamp,
    Authentication: authSecret,
    Accept: "application/json",
    "content-type": "application/x-www-form-urlencoded",
  };
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 500000,
  headers: getHeaders(),
});

const loyaltyInstance = axios.create({
  baseURL: process.env.REACT_APP_LOYALTY_BASE_URL,
  timeout: 60000,
  headers: {
    "access-token": "q6wfRLb+4tzBT4Mra18VhoPM9Hqp4Hkhmhf7YrayIl0=",
    "sys-id": "b743e44516a4f13fa0802cca41eeabc54dda2b97",
  },
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let adminInfo;
  if (Cookies.get("adminInfo")) {
    adminInfo = JSON.parse(Cookies.get("adminInfo"));
  }

  return {
    ...config,
  };
});

const responseBody = (response) => response.data;

const manageServiceRequests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),

  post: async (url, body, additionalHeaders = {}, unstringify = false) => {
    body = unstringify ? body : qs.stringify(body);
    const response = await instance.post(url, body, {
      headers: { ...additionalHeaders },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    });
    return responseBody(response);
  },

  put: async (url, body, additionalHeaders = {}, useFirst = false) => {
    body = useFirst ? body : qs.stringify(body);
    const response = await instance.put(url, body, {
      headers: { ...additionalHeaders },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    });
    return responseBody(response);
  },

  // put: (url, body, headers) =>
  //   instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url) => instance.delete(url).then(responseBody),
};

const loyaltyRequests = {
  get: (url, body, headers) =>
    loyaltyInstance.get(url, body, headers).then(responseBody),

  post: async (url, body, additionalHeaders = {}, unstringify = false) => {
    body = unstringify ? body : qs.stringify(body);
    const response = await loyaltyInstance.post(url, body, {
      headers: { ...additionalHeaders },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    });
    return responseBody(response);
  },

  put: async (url, body, additionalHeaders = {}, useFirst = false) => {
    body = useFirst ? body : qs.stringify(body);
    const response = await loyaltyInstance.put(url, body, {
      headers: { ...additionalHeaders },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    });
    return responseBody(response);
  },

  // put: (url, body, headers) =>
  //   loyaltyInstance.put(url, body, headers).then(responseBody),

  patch: (url, body) => loyaltyInstance.patch(url, body).then(responseBody),

  delete: (url) => loyaltyInstance.delete(url).then(responseBody),
};

const reqeusts = {
  manageServiceRequests,
  loyaltyRequests,
};
export default reqeusts;
