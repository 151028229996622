import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  redeemedVouchers: [],
  transactions: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setRedeemedVouchers: (state, action) => {
      state.redeemedVouchers = action.payload;
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    resetUser(state) {
      return initialState;
    },
  },
});

export const { setUserData, setRedeemedVouchers, setTransactions, resetUser } =
  userSlice.actions;

export default userSlice.reducer;
