import React from "react";

const Select = ({ valueSelected, setValueSelected, options }) => {
  const handleSelectChange = (event) => {
    setValueSelected(JSON.parse(event.target.value));
  };

  return (
    <div>
      {/* The <select> element with options */}
      <select
        className="p-2 pl-4 placeholder:text-blue_gray-200 text-left sm:text-sm tracking-[-0.40px] w-full border border-blue_gray-100 border-solid text-blue_gray-200 rounded-lg min-w-full max-w-full"
        value={JSON.stringify(valueSelected)}
        onChange={handleSelectChange}
      >
        {options.map((option) => {
          return (
            <option key={JSON.stringify(option)} value={JSON.stringify(option)}>
              {option?.displayValue || option}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export { Select };
