// ModalComponent.js
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Modal from "react-modal";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    width: "400px",
    padding: "20px",
    border: "none",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

let buttonStyle = {
  "background": "#3AC656",
  color: "white",
  padding: "12px 20px",
  margin: "5px",
  borderRadius: "12px",
  width: "100%",
  height: "55px",
  fontSize: "18px",
  fontWeight: "600",
  marginBottom: "8px",
  zIndex: 3,
};
const inputStyles = {
  padding: "12px, 16px, 12px, 16px",
  margin: "10px 0",
  width: "100%",
  borderColor: "#E4E7EC",
  borderRadius: "8px",
  marginBottom: "8px",
};
const headingStyle = {
  color: "#204391",
  fontSize: "20px",
  fontWeight: "700",
  lineHeight: "24.2px",
  textAlign: "left",
  gap: "39px",
  marginBottom: "8px",
};
const textStyles = {
  color: "#606268",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "24px",
  textAlign: "left",
  marginBottom: "8px",
};

const SignUpModal = ({
  isOpen,
  onRequestClose,
  onLoggedIn,
  processing,
  handleGetUserLoginDetails,
  handleUserSignIn,
  register,
  getValues,
  handleSubmit,
  errors,
  setValue,
  step,
  setStep,
}) => {
  const [currentView, setCurrentView] = useState("login"); // Can be 'signup' or 'otp'
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isWrongPass, setIsWrongPass] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const switchToLogin = () => setCurrentView("login");
  const switchToSignup = () => setCurrentView("signup");
  const switchToOTP = () => setCurrentView("otp");

  // useEffect hook to swtich to OTP to deal with the error of too many re-renders 
  useEffect(() => {
    switch (step) {
      case 2:
        setCurrentView("otp");
        return;
      default:
        break;
    }
  }, [step]);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleOTPChange = (element, index) => {
    if (isNaN(element.value)) return false; // Only accept numeric values
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Auto-focus to next input field after a digit is typed
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSignup = () => {
    // Here you would send the phone number to your backend to initiate the OTP process
    // For now, let's just switch to the OTP view
    switchToOTP();
  };

  const handleOTPSubmit = () => {
    // Here you would verify the OTP
    console.log(`Entered OTP is: ${otp.join("")}`);
    // you can make sure that OTP is correct from backend here
    setCurrentView("login");
    setStep(3);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === repeatPassword && password.length) {
      // Here you would handle the password submission, possibly sending it to your backend
      // ************************************
      setCurrentView("done");
      // Close modal after password set
      // onLoggedIn(true);
    } else {
      // Handle error case
      setIsWrongPass(true);
      console.error("Passwords do not match or are empty");
    }
  };

  console.log(`Processing: ${processing}`);
  // buttonStyle["background"] = processing ? "#59DE73" : "#3AC656";
  let text = processing ? "Loading..." : "Login";

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <div style={{ textAlign: "center" }}>
        {/* Toggle between Login and SignUp */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <button
            onClick={switchToLogin}
            style={{
              marginRight: "10px",
              color: currentView === "login" ? "#204391" : "#98A2B3",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "inter",
              padding: "8px",
              width: "100%",
            }}
          >
            Login
          </button>
          {/* <button
            onClick={switchToSignup}
            style={{
              color: currentView === "signup" ? "#204391" : "#98A2B3",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "inter",
              padding: "8px",
              width: "100%",
            }}
          >
            Sign Up
          </button> */}
        </div>
        <hr style={{ color: "gray", marginTop: "-8px", marginBottom: "8px" }} />
        {currentView === "login" && (
          // Login Form
          <>
            <div>
              <p style={headingStyle}>Welcome Back</p>
              <p style={textStyles}>Enter your phone number and password</p>
            </div>
            <input
              readOnly={step === 1 || step === 3}
              placeholder="Phone Number"
              type="tel"
              maxLength={10}
              autoComplete="tel"
              style={inputStyles}
              {...register("mobileNumber", {
                required: "Mobile number is required",
                minLength: {
                  value: 10,
                  message: `Number must be 10 characters`,
                },
                maxLength: {
                  value: 10,
                  message: `Number must be 10 characters`,
                },
              })}
            />
            {step === 1 && (
              <input
                type="password"
                placeholder="Password"
                autoComplete="current-password"
                style={inputStyles}
                {...register("password", {
                  required: "Password is required",
                })}
              />
            )}
            {step === 3 && (
              <>
                <input
                  type="password"
                  placeholder="New Password"
                  autoComplete="current-password"
                  style={inputStyles}
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  style={inputStyles}
                  {...register("repeatPassword", {
                    required: "Password is required",
                    validate: (value) => value === getValues("password") || "Passwords must match",
                  })}
                />
              </>
            )}
            { step === 3
            ?  <button
                disabled={processing}
                style={{...buttonStyle,
                  "background": processing ? "#6BFA88" : "#3AC656"}
                }
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(getValues("password") === getValues("repeatPassword") ? handleUserSignIn : toast.error("Passwords do not match"))();
                }}
              >
                {!processing ? "Register" : "Loading..."}
              </button>
            : <button
              disabled={processing}
              style={{...buttonStyle,
                "background": processing ? "#6BFA88" : "#3AC656"}
              }
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(
                  step === 0 ? handleGetUserLoginDetails : handleUserSignIn
                )();
              }}
            >
              {!processing ? "Login" : "Loading..."}
            </button>
            }
          </>
        )}

        {currentView === "signup" && (
          // Sign Up Form
          <>
            <h2 style={headingStyle}>Sign up and Start Earning Points</h2>
            <p style={textStyles}>Enter your phone number to register</p>
            <input
              type="tel"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Phone Number"
              style={inputStyles}
            />
            <button
              onClick={handleSignup}
              style={{
                background: "#3AC656",
                color: "white",
                padding: "12px 20px",
                margin: "5px",
                borderRadius: "12px",
                width: "100%",
                height: "55px",
                fontSize: "18px",
                fontWeight: "600",
                marginBottom: "8px",
                zIndex: 3,
              }}
            >
              Sign Up
            </button>
          </>
        )}

        {currentView === "otp" && (
          // OTP Form
          <>
            <h2 style={headingStyle}>Enter OTP</h2>
            <p style={textStyles}>
              Enter the code from the sms we sent to {phoneNumber}
            </p>
            <div>
              {otp.map((data, index) => {
                return (
                  <input
                    key={index}
                    type="text"
                    value={otp[index]}
                    maxLength="1"
                    onChange={(e) => handleOTPChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                    style={{
                      width: "40px",
                      marginRight: "10px",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  />
                );
              })}
            </div>
            <button onClick={handleOTPSubmit} style={buttonStyle}>
              Confirm OTP
            </button>
          </>
        )}
        {currentView === "createPassword" && (
          <div style={{ textAlign: "center" }}>
            <h2 style={headingStyle}>Create Password</h2>
            <p style={textStyles}>
              Create your password to keep your account secured.
            </p>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              style={inputStyles}
            />
            <input
              type="password"
              value={repeatPassword}
              onChange={handleRepeatPasswordChange}
              placeholder="Repeat password"
              style={inputStyles}
            />
            {isWrongPass && <p color="red">password do not match</p>}
            <button onClick={handlePasswordSubmit} style={buttonStyle}>
              Confirm
            </button>
          </div>
        )}
        {currentView === "done" && (
          <div style={{ textAlign: "center" }}>
            <img
              src="images/success.png" // Replace with the path to your success icon image
              alt="Success"
              style={{
                margin: "0 auto",
                display: "block",
                width: "100px",
                height: "100px",
              }}
            />
            <h2 style={headingStyle}>
              Congratulations! Now lets pack some points.
            </h2>
            <p style={textStyles}>
              Shop from our merchants and pack up some points. We put the fun in
              shopping. Enjoy!
            </p>
            <button onClick={onRequestClose} style={buttonStyle}>
              Done
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SignUpModal;
