import requests from "utils/httpService";

const postRequests = {
  loginUser: (values) =>
    requests.manageServiceRequests.post(`/customers/login`, values),
  redeemAirtime: (values) =>
    requests.manageServiceRequests.post("/topups/process", values),
  redeemVoucher: (values) =>
    requests.manageServiceRequests.post("/orders/voucher/process", values),
};

export default postRequests;
