import React from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Img, Line, Text } from "components";

import AirtimeM1Modal from "modals/AirtimeM1";
import VoucherM1Modal from "modals/VoucherM1";
import Rewards from "components/HomeRewards";
import SignUpModal from "./login/signup";

const ExtraPage =()=>{
    const navigate = useNavigate();
    const [isOpenVoucherM1Modal, setVoucherM1Modal] = React.useState(false);
    const [isOpenAirtimeM1Modal, setAirtimeM1Modal] = React.useState(false);
    const [isSignedupModal, setSignupModal] = React.useState(false);
  
    const closeModal = () => {
      setSignupModal(false);
    };
  
  
    function handleOpenVoucherM1Modal() {
      setVoucherM1Modal(true);
    }
    function handleCloseVoucherM1Modal() {
      setVoucherM1Modal(false);
    }
    function handleOpenAirtimeM1Modal() {
      setAirtimeM1Modal(true);
    }
    function handleCloseAirtimeM1Modal() {
      setAirtimeM1Modal(false);
    }
  
    return(
        <>
        <header
          className="common-pointer bg-white-A700 flex flex-col items-center justify-center md:px-5 w-full"
          
        >
          <div className="bg-indigo-800 flex flex-1 flex-col items-center justify-start w-full">
            <div className="bg-indigo-800 flex sm:flex-col flex-row gap-2 sm:hidden items-center justify-end w-full">
              <Button
                className="cursor-pointer font-medium leading-[normal] min-w-[252px] text-base text-center text-white-A700"
                shape="round"
                size="sm"
              >
                support@digistoreafrica.com
              </Button>
              <Button
                className="cursor-pointer font-medium leading-[normal] min-w-[142px] text-base text-center text-white-A700"
                shape="round"
                size="sm"
              >
                +233303967121
              </Button>
              <Button
                className="cursor-pointer font-medium leading-[normal] min-w-[151px] text-base text-center text-white-A700"
                shape="round"
                size="sm"
              >
                +233302735088
              </Button>
            </div>
          </div>
          
          <div className="bg-white-A700 flex md:flex-1 flex-row font-montserrat md:gap-10 items-center justify-between py-5 w-4/5 md:w-[100%]">
            <Img
              className="h-[63px] w-[65px]"
              src="images/img_union.svg"
              alt="union"
            />
            <div className="flex flex-row gap-[10px] items-start justify-start w-auto relative">
              <Button
                className="cursor-pointer flex items-center justify-center min-w-[100px] rounded-[24px]"
                onClick={() => navigate("/")}
                leftIcon={
                  <Img
                    className="h-8 mr-[17px] sm:mr-[10px]"
                    src="images/img_trophy.svg"
                    alt="trophy"
                  />
                }
                color="deep_orange_50"
                size="xs"
                variant="fill"
              >
                <div className="text-left text-xl sm:text-xs">000 pts</div>
              </Button>
              <div style={{position: "relative"}}>
              <Button
                className="flex h-12  items-center justify-center rounded-[50%] w-12 "
                color="deep_orange_50"
                size="sm"
                variant="fill"
                onClick={() => setSignupModal(!isSignedupModal)}
              >
                <Img
                  className="h-6 "
                  src="images/img_iconuser.svg"
                  alt="iconuser"
                />
              </Button>
             
              </div>
              <div className="absolute top-16 right-0 left-[-80px]  bg-black bg-opacity-50 flex justify-center items-center h-[10px]" >
             <div className="bg-white p-5 rounded-lg shadow-lg" style={{backgroundColor: "#FFFFE0", position: "absolute", top:-10, right: 0, height: 100 }} >
             <div className="flex flex-row items-center justify-center mb-4">
              <div>
              <span className="text-yellow-500 bg-white rounded-full p-2 text-2xl">
              <strong>!</strong>
              </span>
              </div>
          <h2 className="text-lg font-bold mb-2 text-center">
            You have to be logged in to use this feature
          </h2>
          </div>
          
        </div>
      </div>
            </div>
          </div>
        </header>
         <div className="font-montserrat h-[355px] sm:p-[] sm:pl-[] sm:pr-[]  relative w-full">
          <Img
            className="h-[355px]  m-auto sm:mb-[]   sm:mt-[] object-cover w-full"
            src="images/image22.png"
            alt="rectangleSixtyFive"
          />
          
        </div>
        <div className="bg-white-A700 flex flex-col font-montserrat gap-[55px] items-center justify-start max-w-[1920px] py-[120px] w-full">
          <div className="flex flex-col gap-9 items-center justify-start md:px-5 w-[616px] md:w-full">
            <Text
              className="leading-[116.00%] max-w-[616px] md:max-w-full sm:text-[25px] md:text-[39.95px] text-[43.95px] text-center text-indigo-800 tracking-[1.32px]"
              size="txtMontserratSemiBold4395"
            >
              Explore and Earn with our Partnered Merchants
            </Text>
            <Text
              className="leading-[150.00%] max-w-[616px] md:max-w-full sm:text-[15px] text-center text-gray-900_99 text-xl tracking-[0.30px]"
              size="txtMontserratRegular20Gray90099"
            >
              Enjoy rewards and points with every purchase from our carefully
              curated list of merchants. Shop at your favorite spot and unlock
              exclusive benefits at every checkout
            </Text>
          </div>
          <div className="flex flex-col items-start justify-start max-w-[903px] md:px-5 w-full">
            <div className="flex flex-col gap-[18px] items-start justify-start w-full">
              <div className="md:gap-5 gap-[17px] grid md:grid-cols-2 grid-cols-3 justify-center min-h-[auto] w-full">
              <a href="https://dipnchipsgh.com/">
                <div className="h-[110px] relative w-full">
                
                  <Img
                    className="h-[110px] m-auto object-cover rounded-[10px] w-full"
                    src="images/img_rectangle102.png"
                    alt="rectangle102"
                    />
                  <Img
                    className="absolute h-12 inset-[0] justify-center m-auto object-cover w-12"
                    src="images/img_image15.png"
                    alt="imageFifteen"
                  />
                </div>
                  </a>
                  <a href="https://www.andosrestaurant.com/" >
                <div className="h-[110px] relative w-full">
                  <Img
                    className="h-[110px] m-auto object-cover rounded-[10px] w-full"
                    src="images/img_rectangle103.png"
                    alt="rectangle103"
                  />
                  <Img
                    className="absolute bottom-1/4 h-12 inset-x-[0] mx-auto object-cover w-[23%] sm:w-[40%]"
                    src="images/img_maskgroup.png"
                    alt="maskgroup"
                  />
                </div>
                  </a>
                  <a href="https://papaspizzagh.com/">
                <div className="h-[110px] relative w-full">
                  <Img
                    className="h-[110px] m-auto object-cover rounded-[10px] w-full"
                    src="images/img_rectangle104.png"
                    alt="rectangle104"
                  />
                  <Img
                    className="absolute h-[47px] inset-[0] justify-center m-auto object-cover w-[46%] sm:w-[70%]"
                    src="images/img_logomain1.png"
                    alt="logomainOne"
                  />
                </div>
                  </a>
                  <a href="https://buy.digistoreafrica.com/waakyeguyguy/order">
                <div className="h-[110px] relative w-full">
                  <Img
                    className="h-[110px] m-auto object-cover rounded-[10px] w-full"
                    src="images/img_rectangle105.png"
                    alt="rectangle105"
                  />
                  <Img
                    className="absolute h-12 inset-[0] justify-center m-auto object-cover w-[47px] "
                    src="images/img_logomer11155.png"
                    alt="logomer11155"
                  />
                </div>
                  </a>
                  <a href="https://apps.apple.com/gh/app/starbites/id1573083411">
                <div className="h-[110px] relative w-full">
                  <Img
                    className="h-[110px] m-auto object-cover rounded-[10px] w-full"
                    src="images/img_rectangle106.png"
                    alt="rectangle106"
                  />
                  <Img
                    className="absolute h-[49px] inset-[0] justify-center m-auto object-cover w-[31%] sm:w-[60%]"
                    src="images/img_starbiteslogoplain.png"
                    alt="starbiteslogopl"
                  />
                </div>
                  </a>
                  <a href="http://www.popkulture22.com">
                <div className="h-[110px] relative w-full">

                  <Img
                    className="h-[110px] m-auto object-cover rounded-[10px] w-full"
                    src="images/img_rectangle107.png"
                    alt="rectangle107"
                  />
                  <Img
                    className="absolute bottom-1/4 h-12 inset-x-[0] mx-auto object-cover rounded-[50%] w-12"
                    src="images/img_logomer06016_48x48.png"
                    alt="logomer06016"
                  />
                </div>
                </a>
                <a href="https://buy.digistoreafrica.com/pizzaexpressgh/order" >
                <div className="h-[110px] relative w-full">
                  <Img
                    className="h-[110px] m-auto object-cover rounded-[10px] w-full"
                    src="images/img_rectangle107_110x289.png"
                    alt="rectangle107"
                  />
                  <Img
                    className="absolute bottom-1/4 h-12 inset-x-[0] mx-auto object-cover rounded-[50%] w-12"
                    src="images/img_logomer06016_1.png"
                    alt="logomer06016"
                  />
                </div>
                </a>
                <a href="https://buy.digistoreafrica.com/secondpartnerfoods">
                <div className="h-[110px] relative w-full">
                  <Img
                    className="h-[110px] m-auto object-cover rounded-[10px] w-full"
                    src="images/img_rectangle107_1.png"
                    alt="rectangle107"
                  />
                  <Img
                    className="absolute bottom-1/4 h-12 inset-x-[0] mx-auto object-cover rounded-[50%] w-12"
                    src="images/img_logomer06016_2.png"
                    alt="logomer06016"
                  />
                </div>
                </a>
                <a href="https://micarshgrills.com/micarshgrills/order">
                <div className="h-[110px] relative w-full">
                  <Img
                    className="h-[110px] m-auto object-cover rounded-[10px] w-full"
                    src="images/img_rectangle107_2.png"
                    alt="rectangle107"
                  />
                  <Img
                    className="absolute bottom-1/4 h-12 inset-x-[0] mx-auto object-cover rounded-[50%] w-12"
                    src="images/img_logomer06016_3.png"
                    alt="logomer06016"
                  />
                </div>
                </a>
                <a href="https://buy.digistoreafrica.com/bluecheese">
                <div className=" h-[110px] relative w-full sm:w-full">
                <Img
                  className="h-[110px] m-auto object-cover rounded-[10px] w-full"
                  src="images/img_rectangle107_3.png"
                  alt="rectangle107"
                />
                <Img
                  className="absolute bottom-1/4 h-12 inset-x-[0] mx-auto object-cover rounded-[50%] w-12"
                  src="images/img_logomer06016_4.png"
                  alt="logomer06016"
                />
              </div>
              </a>
              </div>
              
            </div>
          </div>
        
        </div>
        <div className="bg-gray-100 flex flex-col font-montserrat gap-[59px] h-[621px] md:h-auto items-center justify-start max-w-[1920px] py-[100px] w-full sm:mb-[-80px]">
          <div className="flex flex-col gap-[29px] items-center justify-start md:px-5 w-auto md:w-full">
            <Text
              className="leading-[116.00%] max-w-[764px] md:max-w-full md:text-5xl sm:text-[25px] text-[56px] text-center text-indigo-800"
              size="txtMontserratSemiBold56"
            >
              Join the Digipreneur community
            </Text>
            <Text
              className="leading-[150.00%] max-w-[669px] md:max-w-full sm:text-[15px] text-center text-gray-900_99 text-xl tracking-[0.30px]"
              size="txtMontserratRegular20Gray90099"
            >
              Get instant update on all promos and more. Join Didistore today
              and let you shopping spree become a rewarding journey
            </Text>
          </div>
          <div className="border border-green-500 border-solid flex flex-col font-inter items-center justify-center p-5 md:px-5 rounded-[34px] sm:w-[150px] w-[318px]">
            <a
                target="_blank" // Opens the link in a new tab/window
                rel="noopener noreferrer"
                href="https://forms.gle/TcEAqVyRLQ4uZVkn6" 
            
            >
            <Text
              className="text-green-500 text-xl tracking-[0.50px] w-auto"
              size="txtInterMedium20"
              
            >
              Join Now
            </Text>
            </a>
          </div>
          <Img
            style={imageStyle}
            src="images/img_group44.svg"
            alt="groupFortyFour"
          />
        </div>
        <div className="bg-indigo-800 flex flex-col font-generalsans items-center justify-start py-[60px] w-full">
          <div className="flex flex-col gap-11 items-center justify-start max-w-[1335px] mx-auto md:px-5 w-4/5 md:w-full">
            <div className="flex flex-col gap-8 items-start justify-start w-full">
              <Line className="bg-white-A700_33 h-px w-full" />
              <div className="flex flex-row md:gap-10 items-center justify-between w-full">
                <div className="flex flex-row gap-2 items-center justify-start pl-1 pr-3 py-1 w-auto">
                  <Img
                    className="h-7 md:h-auto rounded-[50%] w-7"
                    src="images/img_ellipse1.png"
                    alt="ellipseOne"
                  />
                  <Text
                    className="text-lg text-white-A700_87 w-auto"
                    size="txtGeneralSansMedium18"
                  >
                    Ghana
                  </Text>
                </div>
                <div className="flex flex-row gap-3 items-start justify-start w-auto">
                  <Img
                    className="common-pointer h-5 w-5"
                    src="images/img_icons8twittercircledfilled.svg"
                    alt="icons8twitterci"
                    onClick={() => navigate("")}
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8facebook.svg"
                    alt="icons8facebook"
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8instagramnewfilled.svg"
                    alt="icons8instagram"
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8linkedin.svg"
                    alt="icons8linkedin"
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8youtubeplayfilled.svg"
                    alt="icons8youtubepl"
                  />
                  
                </div>
              </div>
            </div>
            <div className="flex sm:flex-col flex-row md:gap-10 gap-[75px] items-start justify-start w-full">
              <Text
                className="max-w-[303px] md:max-w-full text-lg text-white-A700 tracking-[0.18px]"
                size="txtGeneralSansRegular18"
              >
                The Orangery, 98 La-Bawaleshi Rd, East Legon – Accra, Ghana
                Mon-Fri | 8:00am - 5:00pm
              </Text>
              <Text
                className="text-lg text-white-A700 tracking-[0.18px]"
                size="txtGeneralSansRegular18"
              >
                <>
                  (+233 302 735088) <br />
                  (+233 303 967121)
                </>
              </Text>
              <Text
                className="text-lg text-white-A700 tracking-[0.18px] w-auto"
                size="txtGeneralSansRegular18"
              >
                support@digistoreafrica.com
              </Text>
            </div>
            <div className="flex sm:flex-col flex-row md:gap-11 items-start justify-between w-full">
              <Text
                className="text-lg text-white-A700 tracking-[0.18px] w-auto"
                size="txtGeneralSansRegular18"
              >
                <a
                  href="javascript:"
                  className="text-green-500 font-generalsans text-left font-normal underline"
                >
                  iPay Solutions Limited
                </a>
                <span className="text-green-500 font-generalsans text-left font-normal">
                  {" "}
                </span>
                <span className="text-white-A700 font-generalsans text-left font-normal">
                  2023. All Rights Reserved.
                </span>
              </Text>
              <Text
                className="text-lg text-white-A700 tracking-[0.18px] w-auto"
                size="txtGeneralSansRegular18"
              >
                <span className="text-white-A700 font-generalsans text-left font-normal">
                  Designed by
                </span>
                <span className="text-green-500 font-generalsans text-left font-normal">
                  {" "}
                </span>
                <a
                  href="javascript:"
                  className="text-green-500 font-generalsans text-left font-normal underline"
                >
                  Simpliexpand
                </a>
              </Text>
            </div>
          </div>
        </div>
        </>
    )
}

export default ExtraPage;

const imageStyle = {
    display: 'block',
    width: '100%',
    height: 'auto',
    margin: '0 auto',
    padding: '0',
  };