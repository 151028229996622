import React, { useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import AirtimeM1Modal from "modals/AirtimeM1";
import VoucherM1Modal from "modals/VoucherM1";
import Rewards from "components/HomeRewards";
import SignUpModal from "./login/signup";

import { Button, Img, Line, Text } from "components";
import postRequests from "api/post";
import getRequests from "api/get";
import { useDispatch, useSelector } from "react-redux";
import {
  setRedeemedVouchers,
  setTransactions,
  setUserData,
} from "features/slices/userSlice";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { resetUser } from "features/slices/userSlice";
import { Carousel } from "react-responsive-carousel";

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const phone = searchParams.get("phone");
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      mobileNumber: searchParams.get("phone") || "",
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.user);
  const rehydrated = useSelector((state) => state._persist.rehydrated);

  const [isOpenVoucherM1Modal, setVoucherM1Modal] = React.useState(false);
  const [isOpenAirtimeM1Modal, setAirtimeM1Modal] = React.useState(false);
  const [isSignedupModal, setSignupModal] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(!!user);
  const [processing, setProcessing] = React.useState(false);
  const [loginError, setLoginError] = React.useState({
    status: false,
    message: "",
  });
  const [step, setStep] = React.useState(0);

  useEffect(() => {
    if (phone || rehydrated) {
      if (user === null) {
        setSignupModal(true);
      } else {
        setSignupModal(false);
        setLoggedIn(!!user);
      }
    }
  }, [phone, rehydrated, user]);

  useEffect(() => {
    const fetchAll = async () => {
      const loyaltyData = await getRequests.getUserLoyaltyPoints({
        customer_id: user?._cid,
        merchant: "MER12193",
      });

      const { message = {} } = loyaltyData;
      dispatch(setUserData({ ...user, loyalty: message }));
    };

    if (!!user?._cid) {
      fetchAll();
    }
  }, [dispatch, user?._cid]);

  const closeModal = () => {
    setSignupModal(false);
  };

  function handleOpenVoucherM1Modal() {
    setVoucherM1Modal(true);
  }
  function handleCloseVoucherM1Modal() {
    setVoucherM1Modal(false);
  }
  function handleOpenAirtimeM1Modal() {
    setAirtimeM1Modal(true);
  }
  function handleCloseAirtimeM1Modal() {
    setAirtimeM1Modal(false);
  }

  const handleGetUserLoginDetails = async (values) => {
    try {
      setProcessing(true);
      setLoginError({
        status: false,
        message: "",
      });
      const res = await getRequests.checkUser(values?.mobileNumber);
      const { status, data } = res;

      if (Number(status) === 0) {
        if (data?.customer_has_account) {
          setValue("mobileNumber", data?.customer_phone);
          toast.success(`Please enter your password`);
          setStep(1);
        } else {
          toast.success(`Please register an account`);
          setValue("fullName", data?.customer_name);
          setValue("mobileNumber", data?.customer_phone);
          setValue("email", data?.customer_email);
          setValue("birthDay", data?.customer_dob?.split("-")[0]);
          setValue("birthMonth", data?.customer_dob?.split("-")[1]);
          setStep(2); //Step 2: OPT, Step 3: Enter password
        }
      }

      if (Number(status) !== 0) {
        if (res?.data?.message?.includes("No Customer details found")) {
          toast.error(`No Customer details found. Please register an account`);
          setValue("mobileNumber", values?.mobileNumber);
          setValue("email", "");
        }
      }
    } catch (error) {
      setLoginError({
        status: false,
        message: "ERROR",
      });
      if (error.response) {
        console.log(error.response.data);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleUserSignIn = async (values) => {
    try {
      setProcessing(true);
      setLoginError({
        status: false,
        message: "",
      });
      const data = {
        username: values?.mobileNumber,
        password: values?.password,
        merchant: "MER12193",
      };

      const resData = await postRequests.loginUser(data);

      if (Number(resData?.status) === 0) {
        setLoginError({
          status: true,
          message: resData?.message,
        });
        setProcessing(false);
        const loyaltyData = await getRequests.getUserLoyaltyPoints({
          customer_id: resData?._cid,
          merchant: "MER12193",
        });
        const { message = {} } = loyaltyData;
        dispatch(setUserData({ ...resData, loyalty: message }));

        const res2 = await getRequests.getCustomerRedeemedVouchers({
          phone: resData?._phone,
          merchant: "MER12193",
        });

        if (res2?.code === 200) dispatch(setRedeemedVouchers(res2?.message));

        const res3 = await getRequests.getCustomerTransactions({
          customer_id: resData?._cid,
          merchant: "MER12193",
        });

        if (res3?.code === 200) dispatch(setTransactions(res3?.message));

        setLoggedIn(true);
        closeModal();
      } else {
        toast.error(resData?.message);
      }
      setProcessing(false);
    } catch (error) {
      console.log(error);
      setLoginError({
        status: true,
        message: error.message,
      });
      setProcessing(false);
    }
  };

  async function setIsLoggedIn(values) {
    await handleUserSignIn(values);
  }

  function navigateToLoyalty() {
    navigate("/loyalty");
  }

  const carousel_items_desktop = [
    {
      src: "images/falala-desktop-hero-1.png",
      alt: "Falala banner 1 Digistore",
    },
    {
      src: "images/falala-desktop-hero-2.png",
      alt: "Falala banner 2 Digistore",
    },
  ];

  return (
    <>
      <div className="bg-white-A700 flex flex-col font-generalsans items-center justify-start mx-auto w-auto sm:w-full md:w-full">
        <header className="common-pointer bg-white-A700 flex flex-col items-center justify-center md:px-5 w-full">
          <div className="bg-indigo-800 flex flex-1 flex-col items-center justify-start w-full">
            <div className="bg-indigo-800 flex sm:flex-col flex-row gap-2 sm:hidden items-center justify-end w-full">
              <Button
                className="cursor-pointer font-medium leading-[normal] min-w-[252px] text-base text-center text-white-A700"
                shape="round"
                size="sm"
              >
                support@digistoreafrica.com
              </Button>
              <Button
                className="cursor-pointer font-medium leading-[normal] min-w-[142px] text-base text-center text-white-A700"
                shape="round"
                size="sm"
              >
                +233303967121
              </Button>
              <Button
                className="cursor-pointer font-medium leading-[normal] min-w-[151px] text-base text-center text-white-A700"
                shape="round"
                size="sm"
              >
                +233302735088
              </Button>
            </div>
          </div>

          <div className="bg-white-A700 flex md:flex-1 flex-row font-montserrat md:gap-10 items-center justify-between py-5 w-4/5 md:w-[100%]">
            <div className="sm:hidden">
            <Img
                className="h-[63px] sm:h-[45px]"
                src="images/digi-mobile.png"
                alt="union"
              />
            </div>
            <div className="hidden sm:block">
              <Img
                className="h-[40px] w-auto"
                src="images/digi-mobile.png"
                alt="union"
              />
            </div>

            <div className="flex flex-row gap-[10px] items-start justify-start w-auto relative">
              <Button
                className="cursor-pointer flex items-center justify-center min-w-[100px] rounded-[24px]"
                onClick={() => navigate("/")}
                leftIcon={
                  <Img
                    className="h-8 mr-[17px] sm:mr-[10px] sm:h-6"
                    src="images/img_trophy.svg"
                    alt="trophy"
                  />
                }
                color="deep_orange_50"
                size="xs"
                variant="fill"
              >
                <div className="text-left text-xl sm:text-xs">
                  {user?.loyalty?.points || "000"} pts
                </div>
              </Button>
              <div style={{ position: "relative" }}>
                <Button
                  id="user_profile"
                  className="flex h-12 content-center items-center justify-center rounded-[50%] w-12 sm:h-6 sm:min-w-2 sm:w-10"
                  color="deep_orange_50"
                  size="sm"
                  variant="fill"
                  onClick={
                    !loggedIn
                      ? () => setSignupModal(!isSignedupModal)
                      : () => navigateToLoyalty()
                  }
                >
                  <Img
                    className="h-6 sm:h-6 sm:w-6"
                    src="images/img_iconuser.svg"
                    alt="iconuser"
                  />
                </Button>
              </div>

              {!loggedIn && (
                <div className="absolute top-16 right-0 left-[-80px] bg-black bg-opacity-50 flex justify-center items-center h-[10px] z-10">
                  <div
                    className="bg-white p-5 rounded-lg shadow-lg border-[1px] border-amber-400"
                    style={{
                      backgroundColor: "#FFFFFF",
                      position: "absolute",
                      top: -10,
                      right: 0,
                      height: 100,
                    }}
                  >
                    <div className="flex flex-row items-start justify-evenly mb-4">
                      <div className="mt-1 mr-1">
                        <img
                          className="w-12"
                          src="images/WarningCircle.png"
                          alt="Warning alert"
                        />
                      </div>
                      <h2 className="text-lg font-montserrat font-medium mb-2 text-center sm:text-base sm:text-left sm:ml-2">
                        You have to be logged in to use this feature
                      </h2>
                    </div>
                  </div>
                </div>
              )}

              {loggedIn && (
                <div style={{ position: "relative" }}>
                  <Button
                    id="user_profile"
                    className="flex h-12 content-center items-center justify-center rounded-[50%] w-12 sm:h-6 sm:min-w-2 sm:w-10"
                    color="deep_orange_50"
                    size="sm"
                    variant="fill"
                    onClick={() => {
                      dispatch(resetUser());
                      navigate("/");
                    }}
                  >
                    <Img
                      className="h-10 sm:h-7 sm:w-7"
                      src="images/img-logout.svg"
                      alt="iconuser"
                    />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </header>

        <div className="z-0">
          <Carousel
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={5000}
          >
            {carousel_items_desktop.map(function (item) {
              return (
                <div key={item.alt}>
                  <img
                    className="object-cover w-full bg-no-repeat"
                    src={item.src}
                    alt={item.alt}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>

        <div className="bg-white-A700 flex flex-col font-montserrat items-center justify-start sm:justify-center p-[120px] sm:p-[] sm:mt-5 sm:mb-5 sm:pb-px sm:pt-2.5 md:px-10 sm:px-5 w-full">
          <div className="flex flex-row sm:gap-3.5 gap-[39.1px] items-center justify-start w-auto md:w-full">
            <Img
              className="h-16 md:h-auto object-cover w-16 sm:w-[10%]"
              src="images/img_image15.png"
              alt="dipchips"
            />
            <Img
              className="h-16 md:h-auto object-cover sm:w-[16%] w-[85px]"
              src="images/img_maskgroup.png"
              alt="buka"
            />

            <Img
              className="h-[62px] md:h-auto object-cover sm:w-[15%] w-[174px]"
              src="images/img_logomain1.png"
              alt="papaspizza"
            />
            <Img
              className="h-[63px] md:h-auto object-cover sm:w-[10%] w-[62px]"
              src="images/img_logomer11155.png"
              alt="waakyeguyguy"
            />
            <Img
              className="h-[63px] md:h-auto object-cover sm:w-[10%] w-[63px]"
              src="images/img_logomer06016.png"
              alt="andos"
            />
            <Img
              className="h-[65px] md:h-auto object-cover w-[115px] sm:w-[14%]"
              src="images/img_starbiteslogoplain.png"
              alt="starbites"
            />
          </div>

          <Rewards isLoggedIn={loggedIn} />
        </div>
        <div className="flex flex-col font-inter items-center justify-start sm:p-[] sm:pb-[] sm:pt-[] py-10 w-full">
          <div className="bg-gray-50 flex flex-col items-center justify-start max-w-[1548px] mx-auto px-16 md:px-5 rounded-[30px] w-full">
            <div className="flex md:flex-col flex-row md:gap-10 gap-[139px] items-start justify-between sm:p-[] sm:py-5 py-[100px] w-auto md:w-full">
              <div className="flex flex-col gap-[42px] items-start justify-start w-[581px] sm:w-full">
                <Img
                  className="h-[85px] w-[84px]"
                  src="images/img_layer1.svg"
                  alt="layerOne"
                />
                <div className="flex flex-col font-montserrat gap-9 items-start justify-start w-auto sm:w-full">
                  <Text
                    className="leading-[116.00%] max-w-[580px] md:max-w-full sm:text-[25px] md:text-[39.95px] text-[43.95px] text-indigo-800"
                    size="txtMontserratBold4395"
                  >
                    <>Unlock Rewards with Digistore&#39;s Loyalty Points</>
                  </Text>
                  <Text
                    className="leading-[140.00%] max-w-[580px] md:max-w-full sm:text-[15px] text-blue_gray-800 text-xl tracking-[0.30px]"
                    size="txtMontserratRegular20"
                  >
                    Every purchase made from our affiliated merchants earns you
                    points that can be redeemed for various rewards. What are
                    you waiting for? Start accumulating points today!
                  </Text>
                </div>
                <a
                  target="_blank" // Opens the link in a new tab/window
                  rel="noopener noreferrer"
                  href="http://buy.digistoreafrica.com/"
                >
                  <Button
                    className="cursor-pointer font-semibold h-[63px] rounded-[12px] text-center text-lg sm:w-[150px] w-[285px]"
                    color="green_500_01"
                    size="lg"
                    variant="fill"
                  >
                    Get Started
                  </Button>
                </a>
              </div>
              <div className="flex flex-col font-montserrat gap-5 items-start justify-start w-auto md:w-full">
                <div className="bg-indigo-50 flex flex-col gap-[18px] items-start justify-start max-w-[700px] p-8 sm:px-5 rounded-[12px] w-full">
                  <div className="flex flex-row gap-4 items-center justify-start w-auto">
                    <div className="h-[50px] rounded-lg w-[50px]">
                      <img
                        className="w-full h-full"
                        src="images/earn-points-icon.png"
                        alt="Earn Points"
                      />
                    </div>
                    <Text
                      className="text-2xl md:text-[22px] text-indigo-800 sm:text-xl tracking-[0.30px] w-auto"
                      size="txtMontserratRomanSemiBold24"
                    >
                      Earn Points
                    </Text>
                  </div>
                  <Text
                    className="leading-[140.00%] max-w-[636px] md:max-w-full sm:text-[15px] text-blue_gray-800 text-xl tracking-[0.30px]"
                    size="txtMontserratRegular20"
                  >
                    By shopping at any merchant affiliated with Digistore, you
                    automatically accrue loyalty points. Every purchase
                    contributes to your points balance, which accumulates over
                    time.
                  </Text>
                </div>
                <Line className="bg-indigo-50_01 h-px w-full" />
                <div className="bg-indigo-50_02 flex flex-col gap-[18px] items-start justify-start max-w-[700px] p-8 sm:px-5 rounded-[12px] w-full">
                  <div className="flex flex-row gap-4 items-center justify-start w-auto">
                    <div className="h-[50px] rounded-lg w-[60px]">
                      <img
                        className="w-full h-full"
                        src="images/redeem-rewards-icon.png"
                        alt="Redeem Rewards"
                      />
                    </div>
                    <Text
                      className="leading-[140.00%] max-w-[210px] md:max-w-full text-2xl md:text-[22px] text-indigo-800 sm:text-xl tracking-[0.30px]"
                      size="txtMontserratRomanSemiBold24"
                    >
                      Redeem Rewards
                    </Text>
                  </div>
                  <Text
                    className="leading-[140.00%] max-w-[636px] md:max-w-full sm:text-[15px] text-blue_gray-800 text-xl tracking-[0.30px]"
                    size="txtMontserratRegular20"
                  >
                    <>
                      Accumulated points can be redeemed for a variety of
                      rewards. Whether it&#39;s redeeming for airtime, discount vouchers, or other exclusive offers, your loyalty pays off!
                    </>
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="font-montserrat h-[355px] sm:p-[] sm:pl-[] sm:pr-[] sm-flex-wrap relative w-full mb-5">
          <div className="h-[355px] md:hidden sm:hidden">
            <Img
              className="h-[355px] m-auto object-cover w-full md:object-[75%] brightness-75"
              src="images/falala-desktop-1.png"
              alt="rectangleSixtyFive"
            />
          </div>
          <div className="h-[355px] hidden md:block sm:block">
            <Img
              className="h-[355px] m-auto sm:mb-[] sm:mt-[] object-cover w-full sm:object-[70%] brightness-75"
              src="images/falala-mobile-1.png"
              alt="rectangleSixtyFive"
            />
          </div>
          <div className="absolute md:h-[211px] h-[355px] inset-[0] justify-center m-auto p-[72px] sm:p-[] sm:pb-[] sm:pl-[] sm:pr-[] sm:pt-[] md:px-10 w-full sm:w-full">
            <div className="absolute flex flex-col gap-[25px] sm:gap-[25px] h-max inset-y-[0] items-center justify-start left-[16%] my-auto w-auto">
              <Text
                className="leading-[99.50%] max-w-[630px] md:max-w-full md:text-5xl sm:text-[25px] text-[63.43px] text-white-A700 tracking-[1.90px]"
                size="txtMontserratBold6343"
              >
                <span className="text-white-A700 font-montserrat text-left font-medium">
                  Chop Better{" "}
                </span>
                <span className="text-white-A700 font-montserrat text-left font-normal italic">
                  this
                </span>
                <span className="text-white-A700 font-montserrat text-left font-normal italic">
                  {" "}
                </span>
                <span className="text-white-A700 font-montserrat text-left font-extrabold">
                  Christmas season
                </span>
              </Text>
              <Text
                className="leading-[150.00%] max-w-[602px] md:max-w-full sm:text-[15px] text-white-A700_99 text-xl tracking-[0.30px]"
                size="txtMontserratRegular20WhiteA70099"
              >
                Get ready to seize the best offers on a vast array of products
                from top merchants
              </Text>
            </div>
          </div>
        </div>

        <div className="bg-indigo-800 flex flex-col font-generalsans items-center justify-start py-[60px] w-full">
          <div className="flex flex-col gap-11 items-center justify-start max-w-[1335px] mx-auto md:px-5 w-4/5 md:w-full">
            <div className="flex flex-col gap-8 items-start justify-start w-full">
              <Line className="bg-white-A700_33 h-px w-full" />
              <div className="flex flex-row md:gap-10 items-center justify-between w-full">
                <div className="flex flex-row gap-2 items-center justify-start pl-1 pr-3 py-1 w-auto">
                  <Img
                    className="h-7 md:h-auto rounded-[50%] w-7"
                    src="images/img_ellipse1.png"
                    alt="ellipseOne"
                  />
                  <Text
                    className="text-lg text-white-A700_87 w-auto"
                    size="txtGeneralSansMedium18"
                  >
                    Ghana
                  </Text>
                </div>
                <div className="flex flex-row gap-3 items-start justify-start w-auto">
                  <Img
                    className="common-pointer h-5 w-5"
                    src="images/img_icons8twittercircledfilled.svg"
                    alt="icons8twitterci"
                    onClick={() => navigate("")}
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8facebook.svg"
                    alt="icons8facebook"
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8instagramnewfilled.svg"
                    alt="icons8instagram"
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8linkedin.svg"
                    alt="icons8linkedin"
                  />
                  <Img
                    className="h-5 w-5"
                    src="images/img_icons8youtubeplayfilled.svg"
                    alt="icons8youtubepl"
                  />
                </div>
              </div>
            </div>
            <div className="flex sm:flex-col flex-row md:gap-10 gap-[75px] items-start justify-start w-full">
              <Text
                className="max-w-[300px] md:max-w-full text-lg text-white-A700 tracking-[0.18px]"
                size="txtGeneralSansRegular18"
              >
                The Orangery, 98 La-Bawaleshi Rd, East Legon – Accra, Ghana
                Mon-Fri | 8:00am - 5:00pm
              </Text>
              <Text
                className="text-lg text-white-A700 md:w-full md:text-center tracking-[0.18px]"
                size="txtGeneralSansRegular18"
              >
                <>
                  (+233 302 735088) <br />
                  (+233 303 967121)
                </>
              </Text>
              <Text
                className="text-lg text-white-A700 tracking-[0.18px] w-auto"
                size="txtGeneralSansRegular18"
              >
                support@digistoreafrica.com
              </Text>
            </div>
            <div className="flex sm:flex-col flex-row md:gap-11 items-start justify-between w-full">
              <Text
                className="text-lg text-white-A700 tracking-[0.18px] w-auto"
                size="txtGeneralSansRegular18"
              >
                <a
                  href="javascript:"
                  className="text-green-500 font-generalsans text-left font-normal underline"
                >
                  iPay Solutions Limited
                </a>
                <span className="text-green-500 font-generalsans text-left font-normal">
                  {" "}
                </span>
                <span className="text-white-A700 font-generalsans text-left font-normal">
                  2023. All Rights Reserved.
                </span>
              </Text>
              <Text
                className="text-lg text-white-A700 tracking-[0.18px] w-auto"
                size="txtGeneralSansRegular18"
              >
                <span className="text-white-A700 font-generalsans text-left font-normal">
                  Designed by
                </span>
                <span className="text-green-500 font-generalsans text-left font-normal">
                  {" "}
                </span>
                <a
                  href="javascript:"
                  className="text-green-500 font-generalsans text-left font-normal underline"
                >
                  Simpliexpand
                </a>
              </Text>
            </div>
          </div>
        </div>
      </div>
      {isOpenVoucherM1Modal ? (
        <VoucherM1Modal
          isOpen={isOpenVoucherM1Modal}
          onRequestClose={handleCloseVoucherM1Modal}
        />
      ) : null}

      {isOpenAirtimeM1Modal ? (
        <AirtimeM1Modal
          isOpen={isOpenAirtimeM1Modal}
          onRequestClose={handleCloseAirtimeM1Modal}
        />
      ) : null}

      {isSignedupModal && (
        <SignUpModal
          isOpen={isSignedupModal}
          onRequestClose={closeModal}
          onLoggedIn={setIsLoggedIn}
          {...{
            processing,
            handleGetUserLoginDetails,
            handleUserSignIn,
            register,
            getValues,
            handleSubmit,
            errors,
            setValue,
            step,
            setStep,
          }}
        />
      )}
    </>
  );
};

export default Home;

const imageStyle = {
  display: "block",
  width: "100%",
  height: "auto",
  margin: "0 auto",
  padding: "0",
};
