import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import ExtraPage from "pages/extrapage";
const RedeemVouchers = React.lazy(() => import("pages/RedeemVouchers"));
const Loyalty = React.lazy(() => import("pages/Loyalty"));

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          
          <Route path="/loyalty" element={<Loyalty />} />
          <Route path="/redeemvouchers" element={<RedeemVouchers />} />
          <Route path="/extrapage" element={<ExtraPage />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
