import React, { useEffect, useState } from "react";
import { Button, Img, Text } from "components";
import AirtimeM1Modal from "modals/AirtimeM1";
import VoucherM1Modal from "modals/VoucherM1";
import TicketM1Modal from "modals/TicketM1";
import getRequests from "api/get";
import { get } from "lodash";

const Rewards = ({ isLoggedIn }) => {
  const [data, setData] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showLoginWarning, setShowLoginWarning] = useState(false);
  const [isOpenVoucherM1Modal, setVoucherM1Modal] = useState(false);
  const [isOpenAirtimeM1Modal, setAirtimeM1Modal] = useState(false);
  const [isOpenTicketM1Modal, setTicketM1Modal] = useState(false);
  const [activeCategory, setActiveCategory] = useState("all");

  useEffect(() => {
    const getGiftCards = async () => {
      const data = await getRequests.getGiftCards("MER12193");
      setData(get(data, "data", []));
      setRewards(get(data, "data", []));
    };

    getGiftCards();
  }, []);

  const filterType = (category) => {
    setActiveCategory(category);
    if (category === "all") {
      setRewards(data);
    } else {
      setRewards(
        data.filter((item) => get(item, "main_category") === category)
      );
    }
  };

  const handleOpenVoucherM1Modal = (item) => {
    setSelectedItem(item);
    setVoucherM1Modal(true);
    console.log(item);
  };

  const handleCloseVoucherM1Modal = () => {
    setVoucherM1Modal(false);
  };

  const handleOpenAirtimeM1Modal = (item) => {
    setSelectedItem(item);
    setAirtimeM1Modal(true);
  };

  const handleCloseAirtimeM1Modal = () => {
    setAirtimeM1Modal(false);
  };

  const handleOpenTicketM1Modal = (item) => {
    setSelectedItem(item);
    setTicketM1Modal(true);
  };

  const handleCloseTicketM1Modal = () => {
    setTicketM1Modal(false);
  };

  const getButtonClassName = (category) => {
    const baseClass =
      "cursor-pointer font-medium min-w-[66px] sm:text-[13px] text-center text-xl max-h-[60px] min-h-[60px] ";
    return activeCategory === category
      ? `${baseClass} bg-[#204391] text-white`
      : `${baseClass} focus:bg-[#204391] active:bg-[#204391] active:text-white focus:text-white`;
  };

  return (
    <>
      {/* Your existing JSX for buttons and other UI elements */}
      <div className="flex flex-row gap-4 sm:gap-[4px] items-center justify-center sm:justify-start sm:content-center mt-[40px] sm:overflow-scroll w-[100%] sm:w-[100%] sm:ml-5">
        <Button
          className={getButtonClassName("all")}
          shape="round"
          color="blue_gray_100"
          size="lg"
          variant="outline"
          onClick={() => filterType("all")}
        >
          All
        </Button>
        <Button
          className={getButtonClassName("airtime")}
          shape="round"
          color="blue_gray_100"
          size="lg"
          variant="outline"
          onClick={() => filterType("Airtime")}
        >
          Airtime
        </Button>
        <Button
          className={getButtonClassName("gift-cards")}
          shape="round"
          color="blue_gray_100"
          size="base"
          variant="outline"
          onClick={() => filterType("Gift Vouchers")}
        >
          Vouchers
        </Button>
        <Button
          className={getButtonClassName("tickets")}
          shape="round"
          color="blue_gray_100"
          size="base"
          variant="outline"
          onClick={() => filterType("Tickets")}
        >
          Tickets
        </Button>
      </div>

      {rewards.length === 0 ? (
        <Text
          className="leading-[140.00%] w-full flex justify-center mt-4 text-center md:max-w-full text-2xl sm:text-[15px] md:text-[22px] text-indigo-800 tracking-[0.30px]"
          size="txtMontserratRomanSemiBold24"
        >
          No rewards available.
        </Text>
      ) : (
        <div className="sm:gap-2.5 gap-10 grid md:grid-cols-2 grid-cols-3 sm:grid-cols-2 sm:w-[95%] justify-center min-h-[auto] w-[100%] mt-10">
          {rewards.map((item, index) => {
            // console.log(item);
            return (
              <div
                key={index}
                className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-1 flex-col lg:content-center items-start justify-start rounded-[12px] lg:w-[85%] overflow-hidden"
              >
                <Img
                  className="object-cover rounded-bl-lg rounded-br-lg rounded-tl-[12px] max-h-[230px] rounded-tr-[12px] w-full h-auto sm:h-[93px] min-[310px]:min-w-[140px] max-[330px]:min-h-[140px]"
                  src={item.image}
                  alt={item.name}
                />
                <div className="bg-white-A700 flex flex-col gap-2 items-start p-4 w-full h-full sm:p-2 sm:w-full sm:h-full sm:justify-around">
                  <Text
                    className="leading-[140.00%] max-w-[346px] md:max-w-full text-2xl text-[20px] max-[325px]:text-[8px] sm:text-[12px] md:text-[22px] text-indigo-800 tracking-[0.30px]"
                    size="txtMontserratRomanSemiBold24"
                  >
                    {item.title}
                  </Text>
                  <div className="sm:flex-row flex flex-row gap-2 items-start justify-between w-full sm:items-end sm:justify-evenly">
                    <Text
                      className="flex-1 sm:text-xs text-blue_gray-300 text-xl tracking-[0.30px] w-auto min-[310px]:text-[10px]"
                      size="txtMontserratMedium20"
                    >
                      {item.name}
                    </Text>
                    <Text
                      className="common-pointer flex-1 text-center text-green-500 sm:text-xs text-xl w-auto"
                      size="txtMontserratMedium20Green500"
                      onClick={() => {
                        if (isLoggedIn) {
                          switch (item.main_category) {
                            case "Airtime":
                              handleOpenAirtimeM1Modal(item);
                              break;
                            case "Gift Vouchers":
                              handleOpenVoucherM1Modal(item);
                              break;
                            case "Tickets":
                              handleOpenTicketM1Modal(item);
                              break;
                            default:
                              break;
                          }
                        } else {
                          // Handle the case when the user is not logged in
                          // You can alert the user, open a login modal, etc.
                          setShowLoginWarning(true); // Trigger the warning message
                          // Optionally, set a timeout to hide the warning after a few seconds
                          setTimeout(() => {
                            setShowLoginWarning(false);
                          }, 3000);
                        }
                      }}
                    >
                      Redeem
                    </Text>
                  </div>
                </div>
              </div>
            );
          })}
          {showLoginWarning && (
            <div className="fixed top-0 right-0 left-auto z-50">
              <div
                className="bg-white p-5 rounded-lg shadow-lg border-[1px] border-amber-400"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div className="flex flex-row items-center justify-center ">
                  <span className="text-yellow-500 bg-white rounded-full p-2 text-2xl sm:text-base">
                    <img
                      className="w-12 sm:w-6 sm:h-6"
                      src="images/WarningCircle.png"
                      alt="Warning alert"
                    />
                  </span>
                  <h2 className="text-lg font-bold mb-2 text-center">
                    You have to be logged in to use this feature
                  </h2>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isOpenVoucherM1Modal && (
        <VoucherM1Modal
          isOpen={isOpenVoucherM1Modal}
          onRequestClose={handleCloseVoucherM1Modal}
          selectedItem={selectedItem}
        />
      )}

      {isOpenAirtimeM1Modal && (
        <AirtimeM1Modal
          isOpen={isOpenAirtimeM1Modal}
          onRequestClose={handleCloseAirtimeM1Modal}
          selectedItem={selectedItem}
        />
      )}

      {isOpenTicketM1Modal && (
        <TicketM1Modal
          isOpen={isOpenTicketM1Modal}
          onRequestClose={handleCloseTicketM1Modal}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
};

export default Rewards;
