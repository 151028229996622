import React, { useState } from "react";
import { default as ModalProvider } from "react-modal";
import { useLocation } from "react-router-dom";

import { Button, Img, Input, Select, Text } from "components";
import StepWizard from "react-step-wizard";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import postRequests from "api/post";
import getRequests from "api/get";
import { setUserData } from "features/slices/userSlice";

const ActionButtons = (props) => {
  const handleNext = () => {
    props.nextStep();
  };

  const handleFinish = () => {
    props.lastStep();
  };

  return (
    <div>
      <div>
        <div className="w-full">
          {props.currentStep < props.totalSteps && (
            <Button
              className="cursor-pointer font-medium max-w-full mt-[10px] text-center text-xl tracking-[-0.40px] w-full"
              shape="round"
              color="green_500_01"
              size="lg"
              variant="fill"
              onClick={handleNext}
            >
              Redeem
            </Button>
          )}
          {props.currentStep === props.totalSteps && (
            <Button
              className="cursor-pointer font-medium max-w-[716px] mt-[-10px] text-center text-xl tracking-[-0.40px] w-full "
              shape="round"
              color="green_500_01"
              size="lg"
              variant="fill"
              onClick={handleFinish}
            >
              Redeem
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const One = ({
  user,
  quantity,
  setQuantity,
  selectedItem,
  valueSelected,
  setValueSelected,
  option,
  setOption,
  senderType,
  setSenderTypeSelected,
  setPhoneNumber,
  ...props
}) => {
  const handleNext = () => {
    if (!option) {
      return toast.error("You have not selected who this voucher is for");
    }
    setPhoneNumber(user?._phon);

    if (!valueSelected) {
        return toast.error("You have not selected a reward");
    }
    
    if (!quantity) {
        return toast.error("You have not selected the number of tickets you want");
    }

    if (parseInt(quantity) < 1) {
        return toast.error("You need to redeem at least one ticket");
    }

    if (
      parseFloat(valueSelected?.purchase_point || "0") >
      parseFloat(user?.loyalty?.points || "0")
    ) {
      return toast.error("You do not have enough points to continue.");
    }

    if (option === "Redeem for Self") {
      console.log(option);
      return props.goToStep(3);
    }
    props.nextStep();
  };

  return (
    <div>
      <div className="flex flex-col content-center items-start justify-start w-[90%] md:w-full m-8 sm:item-center sm:ml-5 sm:mr-10 sm:mt-4 sm:w-[90%]">
        <Text
          className="leading-[120.00%] md:text-[26px] text-[30px] text-green-400 sm:text-[20px] tracking-[-1.60px] w-[84%] flex-wrap"
          size="txtInterSemiBold40Indigo800"
        >
          Movies on-demand. Redeem now.
        </Text>
        <div className="flex gap-5 my-5">
          <Button
            className={`${
              option === "Redeem for Self" && "bg-[#204391] text-white-A700"
            } cursor-pointer md:mb-2 sm:mb-0 flex items-center justify-center min-w-[100px] sm:min-w-[90px] rounded-[28px] sm:text-sm focus:bg-[#204391] focus:text-white-A700`}
            leftIcon={
              <Img
                className="h-5 mt-px mb-1 mr-1"
                src="images/img_checkmark_gray_400.svg"
                alt="checkmark"
              />
            }
            color="gray_100_01"
            size="md"
            variant="fill"
            onClick={() => setOption("Redeem for Self")}
          >
            <div
              className={`${
                option === "Redeem for Self" && "bg-[#204391] text-white-A700"
              } sm:text-sm text-left text-xl md:text-lg mx-1 tracking-[-0.40px] focus:bg-[#204391] focus:text-white-A700`}
            >
              Redeem for Self
            </div>
          </Button>
          <Button
            className={`${
              option === "Gift" && "bg-[#204391] text-white-A700"
            } cursor-pointer md:mb-2 sm:mb-0 flex items-center justify-center min-w-[100px] sm:min-w-[90px] rounded-[28px] sm:text-sm focus:bg-[#204391] focus:text-white-A700`}
            leftIcon={
              <Img
                className="h-5 mt-px mb-1 mr-1"
                src="images/img_checkmark_gray_400.svg"
                alt="checkmark"
              />
            }
            color="gray_100_01"
            size="md"
            variant="fill"
            onClick={() => setOption("Gift")}
          >
            <div
              className={`${
                option === "Gift" && "bg-[#204391] text-white-A700"
              } sm:text-sm text-left text-xl md:text-lg mx-1 tracking-[-0.40px] focus:bg-[#204391] focus:text-white-A700`}
            >
              Gift
            </div>
          </Button>
        </div>
        <Text
          className="md:ml-[0] ml-[5px] text-blue_gray-900_01 text-xl tracking-[-0.40px] sm:text-sm mt-3"
          size="txtInterRegular20Bluegray90001"
        >
          Select Package
        </Text>
        <div className="sm:hidden">
          <div className="flex md:flex-row flex-row gap-3 items-start justify-start md:ml-[0] ml-[5px] mt-[17px] overflow-scroll w-[100%] md:w-full flex-wrap">
            {selectedItem?.value?.map((i) => {
              return (
                <Button
                  key={i?.value_label}
                  className={`${
                    valueSelected?.purchase_point === i?.purchase_point &&
                    "bg-[#204391] text-white-A700"
                  } cursor-pointer flex items-center justify-center min-w-[156px] rounded-[28px] sm:text-sm focus:bg-[#204391] focus:text-white-A700 group `}
                  leftIcon={
                    <Img
                      className="h-5 mt-px mb-1 ml-1 mr-2 md:mr-1 sm:h-4 sm:ml-0"
                      src="images/img_checkmark_gray_400.svg"
                      alt="checkmark"
                    />
                  }
                  color="gray_100_01"
                  size="md"
                  variant="fill"
                  onClick={() => {
                    setValueSelected(i);
                  }}
                >
                  <div
                    className={`${
                      valueSelected?.purchase_point === i?.purchase_point &&
                      "bg-[#204391] text-white-A700"
                    } sm:text-sm text-left text-xl tracking-[-0.40px] focus:bg-[#204391] mr-2 md:text-lg md:mr-1 md:py-2`}
                  >
                    {i?.value_label}{" "}
                    <span
                      className={`${
                        valueSelected?.purchase_point === i?.purchase_point
                          ? "text-white-A700"
                          : " text-orange-400 group-focus:text-white-A700"
                      }`}
                    >
                      ({i?.purchase_point}pts)
                    </span>
                  </div>
                </Button>
              );
            })}
          </div>
        </div>
        <div className="hidden sm:block w-full">
          <div className="ml-[5px] mb-5 overflow-scroll w-[90%] sm:flex-wrap md:flex-row md:flex-wrap md:ml-4 sm:ml-0 sm:my-3">
            <Select
              {...{
                valueSelected,
                setValueSelected,
              }}
              options={[
                "Select Package",
                ...selectedItem?.value?.map((i) => ({
                  ...i,
                  displayValue: `${i?.value_label} - ${i?.purchase_point}pts`,
                })),
              ]}
            />
          </div>
        </div>
        <Text
          className="md:ml-[0] ml-[5px] text-blue_gray-900_01 text-xl tracking-[-0.40px] sm:text-sm mt-3"
          size="txtInterRegular20Bluegray90001"
        >
          Select Quantity
        </Text>
        {/* Ticket Quantity */}
        <div className="mt-3 w-1/3">
        <Input
            name="value"
            placeholder="2"
            className="p-0 placeholder:text-blue_gray-300 text-center text-xl tracking-[-0.40px] w-full sm:text-sm sm:p-0"
            wrapClassName="w-full"
            size="sm"
            type="number"
            inputmode="number"
            min="1"
            onChange={(value) => {
            setQuantity(value);
            }}
        />
        </div>
        
        <div className="flex md:flex-col flex-row md:gap-[42px] gap-5 content-end items-start justify-between mt-[30px] sm:mt-2 sm:w-full w-full">
          <div className="bg-green-50 flex sm:flex-1 flex-col flex-wrap items-center justify-start p-4 rounded-[12px] w-[60%] sm:w-[90%] md:w-[80%]">
            <Text
              className="leading-[120.00%] max-w-[409px] md:max-w-full sm:text-[12px] text-gray-900_01 text-xl tracking-[-0.40px]"
              size="txtInterRegular20Gray90001"
            >
              <span className="text-green-800 font-inter text-left font-normal">
                You currently have
              </span>
              <span className="text-gray-900_01 font-inter text-left font-normal">
                {" "}
              </span>
              <span className="text-deep_orange-500 font-inter text-left font-semibold">
                {user?.loyalty?.points}pts
              </span>
              {/* <span className="text-green-800 font-inter text-left font-normal">
                You can purchase the package
              </span> */}
            </Text>
          </div>
          <Button
            className="cursor-pointer font-medium md:mt-0 my-2 sm:text-[15px] text-center text-xl tracking-[-0.40px] w-[40%] md:w-[80%] md:h-12 sm:w-[90%]"
            shape="round"
            color="green_500_01"
            size="lg"
            variant="fill"
            onClick={handleNext}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

const Two = ({
  phoneNumber,
  setPhoneNumber,
  option,
  setOption,
  name,
  setName,
  email,
  setEmail,
  processing,
  setProcessing,
  ...props
}) => {
  const handleNext = () => {
    if (!name) {
      return toast.error("Enter name");
    }

    if (!phoneNumber) {
      return toast.error("Enter mobile number");
    }

    if (!email) {
      return toast.error("Enter email address");
    }
    props.nextStep();
  };

  return (
    <div className="flex flex-col items-start justify-start w-[91%] md:w-full p-[40px] sm:p-5">
      <Text
        className="leading-[100.00%] text-[30px] text-green-400 tracking-[-1.60px] w-full md:text-[26px] sm:text-[20px] sm:w-full mb-[-10] text-base"
        size="txtInterSemiBold40Indigo800"
      >
        Movies on-demand. Redeem now.
      </Text>
      <div className="flex flex-col items-start justify-start md:ml-[0] my-5 w-full md:w-full sm:my-3">
        {option === "Gift" ? (
          <>
            <div className="flex flex-col gap-6 items-start justify-start w-full md:w-full my-5 sm:my-3 sm:gap-3">
              <div className="w-full">
                <Text
                  className="text-blue_gray-900_01 text-xl tracking-[-0.40px] w-full sm:text-sm sm:w-full sm:font-medium"
                  size="txtInterRegular20Bluegray90001"
                >
                  Enter recipient's name
                </Text>
                <Input
                  name="value"
                  placeholder="John Doe"
                  className="p-0 placeholder:text-blue_gray-300 text-center text-xl tracking-[-0.40px] w-full sm:text-sm sm:p-0"
                  wrapClassName="w-full"
                  size="sm"
                  type="text"
                  onChange={(value) => {
                    setName(value);
                  }}
                />
              </div>
              <div className="w-full">
                <Text
                  className="text-blue_gray-900_01 text-xl tracking-[-0.40px] w-full sm:text-sm sm:w-full sm:font-medium"
                  size="txtInterRegular20Bluegray90001"
                >
                  Enter recipient's mobile number
                </Text>
                <Input
                  name="value"
                  placeholder="0208989934"
                  className="p-0 placeholder:text-blue_gray-300 text-center text-xl tracking-[-0.40px] w-full sm:text-sm sm:p-0"
                  wrapClassName="w-full"
                  size="sm"
                  type="tel"
                  maxLength={10}
                  autoComplete="tel"
                  onChange={(value) => {
                    setPhoneNumber(value);
                  }}
                />
              </div>
              <div className="w-full">
                <Text
                  className="text-blue_gray-900_01 text-xl tracking-[-0.40px] w-full sm:text-sm sm:w-full sm:font-medium"
                  size="txtInterRegular20Bluegray90001"
                >
                  Enter recipient's email address
                </Text>
                <Input
                  name="value"
                  placeholder="jdoe@example.com"
                  className="p-0 placeholder:text-blue_gray-300 text-center text-xl tracking-[-0.40px] w-full sm:text-sm sm:p-0"
                  wrapClassName="w-full"
                  size="sm"
                  type="email"
                  autoComplete="email"
                  onChange={(value) => {
                    setEmail(value);
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-6 items-start justify-start w-full md:w-full my-5 sm:my-3 sm:gap-3">
            <Text
              className="text-blue_gray-900_01 text-xl tracking-[-0.40px] w-full sm:text-sm sm:w-full sm:font-medium"
              size="txtInterRegular20Bluegray90001"
            >
              Enter mobile number
            </Text>
            <Input
              name="value"
              placeholder="0208989934"
              className="p-0 placeholder:text-blue_gray-300 text-center text-xl tracking-[-0.40px] w-full sm:text-sm sm:p-0"
              wrapClassName="w-full"
              size="sm"
              type="tel"
              maxLength={10}
              autoComplete="tel"
              onChange={(value) => {
                setPhoneNumber(value);
              }}
            />
          </div>
        )}
      </div>
      <Button
        className="cursor-pointer font-medium max-w-[838px] mt-[10px] sm:text-[15px] text-center text-xl tracking-[-0.40px] w-full md:h-14 sm:h-12 sm:w-full"
        shape="round"
        color="green_500_01"
        size="lg"
        variant="fill"
        onClick={handleNext}
      >
        Redeem
      </Button>
    </div>
  );
};

const Three = ({
  user,
  selectedItem,
  valueSelected,
  setValueSelected,
  networkSelected,
  setNetworkSelected,
  phoneNumber,
  setPhoneNumber,
  processing,
  setProcessing,
  name,
  email,
  ...props
}) => {
  const dispatch = useDispatch();

  const handleNext = async () => {
    const payload = {
      merchant: "MER12193",
      payment_type: "LPTS",
      customer_id: user?._cid,
      source: "ONLINE REWARDS",
      sender_name: user?._name,
      sender_email: user?._email,
      sender_phone: user?._phone,
      total_points: parseFloat(valueSelected?.purchase_point),
      mod_by: user?._phone,
      voucher_list: JSON.stringify({
        0: {
          voucher: selectedItem?.id,
          recipient: {
            0: {
              Name: name || user?._name || "",
              Phone: phoneNumber || user?._phone,
              Email: email || user?._email || "",
              Qty: 1,
              Message: "",
              value: parseFloat(valueSelected?.value_earned),
              price: parseFloat(valueSelected?.purchase_price),
              points: parseFloat(valueSelected?.purchase_point),
            },
          },
        },
      }),
    };

    setProcessing(true);
    const res = await postRequests.redeemVoucher(payload);
    setProcessing(false);

    if (Number(res?.status) === 99) return toast.error(res?.message);
    const loyaltyData = await getRequests.getUserLoyaltyPoints({
      customer_id: user?._cid,
      merchant: "MER12193",
    });
    const { message = {} } = loyaltyData;
    dispatch(setUserData({ ...user, loyalty: message }));

    toast.success(res?.message);
    props.nextStep();
  };

  return (
    <div>
      <div className="flex flex-col items-start justify-start w-[91%] md:w-full p-[40px] sm:p-5">
        <Text
          className="md:text-[26px] text-[30px] text-green-400 sm:text-base tracking-[-1.60px] sm:text-[20px]"
          size="txtInterSemiBold40"
        >
          Confirmation
        </Text>
        <Text
          className="leading-[140.00%] mt-7 text-xl sm:text-[15px] md:text-[20px] text-blue_gray-900_01 tracking-[-0.48px] w-[81%] sm:w-full"
          size="txtInterMedium24"
        >
          <span className="text-blue_gray-900_01 font-inter text-left font-normal mb-[-30] sm:font-medium">
            By clicking on redeem you confirm to redeeming
          </span>
          <span className="text-blue_gray-900_01 font-inter text-left font-medium">
            {" "}
          </span>
          <span className="text-blue_gray-900_01 font-inter text-left font-semibold">
            {valueSelected?.value_label}
          </span>
          <span className="text-blue_gray-900_01 font-inter text-left font-medium">
            {" "}
            ({valueSelected?.purchase_point}pts){" "}
          </span>
          <span className="text-blue_gray-900_01 font-inter text-left font-normal sm:font-medium">
            for{" "}
          </span>
          <span className="text-blue_gray-900_01 font-inter text-left font-semibold">
            {phoneNumber}
          </span>
        </Text>
        <Button
          className="cursor-pointer font-medium max-w-[831px] sm:mt-[32px] mt-[60px] text-center text-xl tracking-[-0.40px] w-full md:h-14 sm:m-[] sm:w-full sm:text-[15px] sm:h-10"
          shape="round"
          color="green_500_01"
          size="lg"
          variant="fill"
          onClick={handleNext}
        >
          Redeem
        </Button>
      </div>

      <br />
    </div>
  );
};

const Four = ({ user, ...props }) => {
  const handleLastStep = () => {
    props.lastStep();
    props.completeCallback();
  };

  return (
    <div>
      <div className="flex flex-col items-start justify-start w-[100%] p-[40px] md:w-full md:p-5 sm:pl-4 sm:pr-2">
        <Text
          className="md:text-[26px] text-[30px] text-green-400 text-base sm:text-[20px] tracking-[-1.60px] mb-3"
          size="txtInterSemiBold40"
        >
          Your Redemption is Confirmed!
        </Text>
        <Text
          className="leading-[140.00%] ml-1 md:ml-[0] my-2 sm:my-2 text-blue_gray-800 text-xl tracking-[0.30px] w-full md:text-base md:my-3 sm:text-[14px]"
          size="txtMontserratRegular20"
        >
          Congratulations! Your recent redemption on Digistore has been
          successfully processed.
        </Text>
        <div className="flex flex-row font-inter gap-3 sm:gap-1 items-center justify-start ml-1 md:ml-[0] mt-[29px] md:mt-4 sm:my-4 w-auto">
          <Button
            className="cursor-pointer flex items-center justify-center sm:min-w-[114px] min-w-[314px] rounded-[24px] sm:text-[10px] md:py-3 sm:py-1"
            leftIcon={
              <Img
                className="h-6 mb-0.5 md:px-2 sm:h-4 sm:pr-2 sm:pl-1 sm:ml-2"
                src="images/img_trophy.svg"
                alt="trophy"
              />
            }
            color="deep_orange_50"
            size="sm"
            variant="fill"
          >
            <div className="text-red-800 sm:min-w-[114px] sm:text-xs text-left text-xl tracking-[-0.40px] mr-1 sm:mr-2">
              You currently have{" "}
              <span className="text-orange-500 font-bold">
                {user?.loyalty?.points}pts
              </span>
            </div>
          </Button>
          <Img
            className="h-8 sm:h-[22px] w-8 sm:w-[22px]"
            src="images/img_question.svg"
            alt="question"
          />
        </div>
        <Text
          className="sm:m-[] ml-1 md:ml-[0] mt-[25px] text-blue_gray-800 text-xl tracking-[0.30px] sm:mt-2 sm:text-[14px] md:mt-8 md:text-base sm:text-base"
          size="txtMontserratRegular20"
        >
          Enjoy your reward and happy shopping!
        </Text>
        <Text
          className="leading-[140.00%] ml-1 md:ml-[0] mt-6 text-blue_gray-800 text-xl tracking-[0.30px] w-full sm:text-[14px] md:mt-8 md:text-base sm:text-sm sm:mt-5"
          size="txtMontserratRegular20"
        >
          <span className="text-blue_gray-800 font-montserrat text-left font-normal">
            Discover additional opportunities to use your loyalty points by
            exploring new packages available for redemption.{" "}
          </span>
          <a
            href="/loyalty"
            className="text-blue-700 font-montserrat text-left font-normal underline"
          >
            Click here
          </a>
          <span className="text-blue_gray-800 font-montserrat text-left font-normal">
            {" "}
            to see what your loyalty points can get you.
          </span>
        </Text>
      </div>
    </div>
  );
};

const TicketM1Modal = (props) => {
  const user = useSelector((state) => state.user.user);

  const [stepWizard, setStepWizard] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [quantity, setQuantity] = useState(null);
  const [valueSelected, setValueSelected] = useState(null);
  const [networkSelected, setNetworkSelected] = useState(null);
  const [senderType, setSenderTypeSelected] = useState(null);
  const [option, setOption] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [processing, setProcessing] = useState(false);

  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const handleStepChange = (e) => {
    console.log("step change");
    console.log(e);
    setActiveStep(e.activeStep - 1);
  };

  const handleComplete = () => {
    alert("You r done. TQ");
  };

  const { selectedItem } = props;

  // Remove StepWizard default transitions
  let noTransitions = {
    enterRight: "all 0s ease-in-out",
    enterLeft: "all 0s ease-in-out",
    exitRight: "all 0s ease-in-out",
    exitLeft: "all 0s ease-in-out",
    intro: "all 0s ease-in-out",
  };

  const selectedImage = selectedItem.image;
  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      className="m-auto !w-[50%] md:!w-[66%] sm:!w-[90%] max-h-[99vh] overflow-y-hidden outline-none sm:m-auto"
      overlayClassName="bg-black-900_89 fixed py-3 flex h-full inset-y-[0] w-full"
      {...props}
    >
      <div className="sm:h-auto md:h-auto sm:w-full md:w-full">
        <div className="bg-white-A700 flex flex-col items-center justify-start w-auto">
          <div className="flex flex-col gap-[10px] items-center justify-start w-full overflow-hidden">
            <div className="md:h-[auto] h-auto relative w-full">
              {/* Display the selected image */}
              {selectedImage && (
                <Img
                  className="h-auto m-auto object-cover sm:p-[] sm:pl-[] sm:pr-[] w-full min-w-full max-h-[290px] sm:max-h-max md:max-h-max"
                  src={selectedItem.image}
                  alt={selectedImage.name}
                />
              )}
              <Img
                className="common-pointer absolute h-[52px] sm:h-auto right-[2%] top-[3%] sm:w-[15%] w-[52px]"
                src="images/img_close.svg"
                alt="close"
                onClick={props.onRequestClose}
              />
            </div>
            <StepWizard
              className="w-full"
              instance={assignStepWizard}
              onStepChange={handleStepChange}
              transitions={noTransitions}
            >
              <One
                {...{
                  user,
                  selectedItem,
                  quantity,
                  setQuantity,
                  valueSelected,
                  setValueSelected,
                  networkSelected,
                  setNetworkSelected,
                  phoneNumber,
                  setPhoneNumber,
                  processing,
                  setProcessing,
                  option,
                  setOption,
                }}
              />
              <Two
                {...{
                  user,
                  selectedItem,
                  valueSelected,
                  setValueSelected,
                  networkSelected,
                  setNetworkSelected,
                  phoneNumber,
                  setPhoneNumber,
                  option,
                  setOption,
                  name,
                  setName,
                  email,
                  setEmail,
                  processing,
                  setProcessing,
                }}
              />
              <Three
                {...{
                  user,
                  selectedItem,
                  valueSelected,
                  setValueSelected,
                  networkSelected,
                  setNetworkSelected,
                  phoneNumber,
                  setPhoneNumber,
                  processing,
                  setProcessing,
                  option,
                  setOption,
                  name,
                  setName,
                  email,
                  setEmail,
                }}
              />
              <Four
                completeCallback={handleComplete}
                {...{
                  user,
                  selectedItem,
                  valueSelected,
                  setValueSelected,
                  networkSelected,
                  setNetworkSelected,
                  phoneNumber,
                  setPhoneNumber,
                  processing,
                  setProcessing,
                  option,
                  setOption,
                  name,
                  setName,
                  email,
                  setEmail,
                }}
              />
            </StepWizard>
          </div>
        </div>
      </div>
    </ModalProvider>
  );
};

export default TicketM1Modal;
